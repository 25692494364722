import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      track_2_title:'Driving Efficiency and Sustainability \n Optimizing Assets, Resources, and Procurement for Lasting Impact',
      track_1_title:"Operational Efficiency: AI Financial planning Solution \n Technical design.",
      invalid_link:"This link is invalid",
      Operation_Failed:"Operation Failed please try again",
      Please_fill_all_fileds:"Please fill all fields",
      please_fill_all_fileds:"Please fill all fields",
      request_send_successfully:"Your request was successfully sent, \n you can send another request to different team",
      ok:"OK",
      admin:"Admin",
      about_team:"About Team",
      my_team:"My Team",
      search_for_memeber:"Search for member in your team",
      search:"Search", 
      members:"Members",
      failed_to_get_data:"Failed to get data, please try again",
      dashboard:"dashboard",
      profile:"my profile",
      mentors:"mentors",
      feedback:"feedback",
      logout:"Logout",
      Email:"Email",
      Passowrd:"Password",
      Forget_Password:"Forget Password?",
      enter_your_password:'Enter your password',
      enter_your_email:'Enter your email',
      Login:"Login",
      Enter_a_vailed_email:"Enter a valid email",
      Log_in_successfully:"Log in successfully",
      This_account_is_not_qualified:"This account is not qualified",
      These_credentials_do_not_match_our_records:"These credentials do not match our records",
      email_is_required:"Email is required",
      password_is_required:"Password is required",
      Rest_link_send_please_check_your_email:"Rest link send please check your email",
      Join_a_team:"Join a team",
      Create_a_team:"Create a team",
      Back:"Back",
      create_team:"Create Team",
      create:"Create",
      Check_Your_Tags:"Check Your Tags",
      turn_on_other_people_will_see_your_team_and_they_can_join_to_it:"Turn on other people will see your team and they can join to it",
      The_name_has_already_been_taken:"The name has already been taken",
      Team_created_successfully:"Team created successfully",
      Team_name_required:"Team_name required",
      Team_english_description_required:"Team english description required",
      Team_arabic_description_required:"Team arabic description required",
      Please_select_at_least_on_tag:"Please select at least one tag",
      Team_Name:"Team Name",
      Team_Description_EN:"Team Description EN",
      Team_Description:"Team Description",
      Team_Description_Ar:"Team Description Ar",
      Search_For_team:"Search for team",
      Send_Request:"+ Send Request",
      Search:"Search",
      no_result:"Sorry no result found",
      request:"Request",
      member:"members",
      see_more:"See more",
      phone:"Phone",
      email:"Email",
      university:"University",
      stream:"Stream",
      graduation_year:"Graduation Year",
      degree:"Degree",
      experience:"Experience",
      years:"Years",
      skill:"Skill",
      request_rejected:"Rejected",
      request_accepted:"Accepted",
      reject:"Reject",
      accept:"Accept",
      request_list_updated_successfully:"Request list updated successfully",
      request_accepted_successfully:"Request accepted successfully",
      failed_accept_request:"Failed accept request",
      request_rejected_successfully:"Request rejected successfully",
      failed_reject_request:"Failed reject request",
      failed_to_get_request:"Failed to get request",
      cancel_Request:"Cancel Request",
      request_canceled_successfully:"Request canceled successfully",
      New_Password:"New Password",
      enter_your_new_passowrd:"Enter your new password",
      Confirm_Password:"Confirm Password",
      Chagne_Password:"Change Password",
      password_should_be_at_least_8_char:"Password should be at least 8 char",
      Confirm_password_is_required:"Confirm password is required",
      password_dosenot_match:"Passwords do not match",
      welcome_to_expro:"Welcome to EXPRO",
      Personal_Inforamtion:"Personal Information",
      Skilles:"Skills",
      Education:"Professional and Educational Details",
      First_Name:"First Name",
      Last_Name:"Last Name",
      Phone_Number:"Phone Number",
      Nationality:"Nationality",
      ID:"ID",
      zip_code:"Zip Code",
      Address:"Address",
      next:"Next",
      First_name_is_required:"First name is required",
      Last_name_is_required:"Last name is required",
      Phone_is_required:"Phone is required",
      Zip_cod_is_required:"Zip code is required",
      Address_is_required:"Address is required",
      Nationality_is_required:"Nationality is required",
      ID_number_is_required:"ID number is required",
      invalid_id: "Please enter a correct ID number",
      invalid_phone: "Please enter a valid phone number",
      check_Your_Skills:"Check your skills",
      Please_selsect_at_least_one_skill:"Please select at least one skill",
      Upload_resume:"Upload resume",
      or_just_drag_and_drop:"or just drag and drop",
      University:"University",
      University_Degree:"University Degree",
      Stream:"Stream",
      Graduation_year:"Graduation year",
      Year_Of_Experince:"Number of Years of Experience",
      submit:"Submit",
      cv:"Upload CV",
      CV_is_required:"CV is required",
      University_name_is_required:"University name is required",
      Degree_is_required:"University Degree is required",
      Stream_is_required:"Stream is required",
      Graduation_year_is_required:"Graduation year is required",
      Year_of_experince_is_required:"Year of experience is required",
      Registration_Success:"Registration Success",
      The_email_has_already_been_taken:"The email has already been taken",
      File_is_too_large:"File is larger than 6MB",
      Supported_files_fromat_are:"Supported files format are",
      sector:"Sector",
      sector_is_required:"Sector is required",
      rounds:"Rounds",
      apply:"Apply Now",
      this_field_is_required:"This field is required",
      text_length_should_be_between:"Text length should be between",
      number_should_be_between:"Number should be between",
      date_should_be_between:"Date should be between",
      email_not_found:"Email not found",
      send_reset_link:'Send reset link',
      field:"Field",
      Book_Now:"Book Now",
      please_Select_Session:"Please select session to book",
      session_booked_successfully:"Session booked successfully",
      failed_to_book_session:"Failed to book session",
      Book_a_session:"Book a session",
      no_available_sessions:"Sorry, no available sessions",
      Search_For_Mentor:"Search for mentor",
      my_session:"My Sessions",
      failed_to_get_sessions:"Failed to get sessions",
      session_list_updated_successfully:"Session list updated successfully",
      mentor_list_updated_successfully:"Mentor list updated successfully",
      no_booked_sessions:"Sorry, no booked sessions",
      mentor_name:"mentor name",
      date:"Date",
      time:"Time",
      download_file:"Download File",
      no_uploaded_file:"No Uploaded File",
      download_image:"Download Image",
      no_uploaded_image:"No Uploaded Image",
      no_feedback:"Sorry, there is no feedback",
      register_now:"register now",
      about_expro:"About EXPRO",
      explore_kafa:"Explore Kafa'athon",
      faqs:"FAQs",
      event_start:"Kafa’athon 2024 Kicks Off in….",
      about_q1:'What is the Efficiency and Government Projects Authority (EXPRO)?',
      about_a1:"EXPRO was formed pursuant to the Cabinet Resolution issued on February 23, 2021 corresponding to Rajab 11, 1442 AH, pursuant to Resolution No. (389) , which stipulates the inclusion of the National Program for Supporting Project Management, Operation and Maintenance in Public Entities (Projects) in the Center for Achieving Spending Efficiency, and transforming the center into the Authority for Spending Efficiency and Government Projects.",
      about_q2:"The Purpose of Establishing the Authority:",
      about_a2_1:"Contributing to achieving spending efficiency in government agencies",
      about_a2_2:"Improving the quality of projects, assets and facilities, infrastructure planning, programmes, initiatives and operational processes funded by the state’s general budget",
      about_a2_3:"Follow up on the implementation of these entities’ programmes and initiatives in a manner that achieves the authority’s objectives.",
      explore_t1:"AI Financial Forecasting and Analysis Solution",
      challenge:"challenge",
      explore_t2:"Develop a software solution that enables relevant entities to accurately calculate, forecast and allocate budgets to upcoming projects or RFPs. The solution should streamline budget management, integrate sustainability metrics for resource re-use, and incorporate a budget variance tool to drive continuous improvement. The tool must enhance decision-making, promote resource efficiency, and support sustainable project planning.",
      Create_session:"Create Session",
      team_name:"Team Name",
      session_time:"Session Time",
      status_action:"Status / Action",
      no_session:"Sorry, there is no sessions",
      status:"Status",
      are_you_sure_you_want_to_cancel_this_session:"Are you sure you want to cancel this session",
      are_you_sure_you_want_to_delete_this_session:"Are you sure you want to delete this session",
      yes:"Yes",
      no:"No",
      session_no:"Session number",
      session_canceled_successfully:"Session canceled successfully",
      session_cancel_failed:"Session cancel failed",
      session_date:"Session Date",
      date_is_required:"Date is required",
      start_time_is_required:"Start time is required",
      end_time_is_required:"End time is required",
      session_start_time:"Session start time",
      session_end:"Session end time",
      end_time_should_be_larger:"Session end time should be larger then session start time by at least 10 min",
      session_created_successfully:"Session created successfully",
      failed_to_crearte_sesssion:"Failed to create session",
      delete:"delete",
      session_deleted_successfully:"Session deleted successfully",
      session_deleted_failed:"Failed to delete session",
      healthcare:"healthcare",
      construction:"construction",
      event_and_coferencese:"Event and conferences",
      Industries_In_Focus:"Industries in Focus",
      step_1_text:"Register for Kafa’athon 2024.",
      step_2_text:"Join or Form a Team",
      step_3_text:"Submit Your Idea",
      step_4_text:"Initial Filtration",
      step_5_text:"Kafa’thon LIVE",
      step_1_date:"10 October",
      step_2_date:"11 October",
      step_3_date:"13 October",
      step_4_date:"14 October",
      step_5_date:"11th-12th of November",
      judges:"judges",
      faq:"FAQs",
      sponsers:"sponsors",
      day:"Day",
      hours:"Hours",
      minutes:"minutes",
      seconds:"seconds",
      contact_us:"contact us",
      Continue_with_email:"Continue with email",
      cor:"Country of Residence",
      cor_is_required:"Country of residence is required",
      full_name_is_required:"Full name is required",
      full_Name:"Full Name",
      jobTitle:"Current Job Title",
      jobTitle_is_required:"Job Title is required",
      terms_label:"By continuing, you agree to Hacker Earth's Privacy Policy and agree to our Terms of Service.",
      click_to_Read:"Click to read more about EXPRO",
      see_less:"see less",
      Eligibility_Criteria:"Eligibility Criteria",
      accetp_terms:"You need to accept terms and conditions to continue registration",
      accetp_terms_team:"You need to accept terms and conditions to join or create team",
      step:"Step",
      partners:"Sponsors",
      what_next:"Kafa'athon Journey",
      dob:"Date of Birth",
      dob_is_required:"Date of Birth is required",
      skills_exprtise:"Skills",
      lang_prof:"Language Proficiency",
      lang_prof_is_required:"Language Proficiency is required",
      educational_level:"Educational Level",
      educational_level_is_required:"Educational Level is required",
      last_attended_university:"Last attended university",
      last_attended_university_is_required:"Last attended university is required",
      captcha_is_required:"Captcha is required",
      captcha_dose_not_match:"Captcha dose not match",
      
 
      
    },
    'ar': {
      invalid_link:"هذا الرابط غير صحيح",
      Operation_Failed:"فشلت المحاولة الرجاء إعادة المحاولة",
      Please_fill_all_fileds:"الرجاء إدخال جميع الحقول",
      please_fill_all_fileds:"الرجاء إدخال جميع الحقول",
      request_send_successfully:"لقد تم إرسال طلبك بنجاح، \n بإمكانك إرسال طلب جديد لفريق أخر",
      ok:"حسناً",
      admin:"أدمن",
      about_team:"نبذة عن الفريق",
      my_team:"فريقي",
      search_for_memeber:"إبحث عن عضو في فريقك",
      search:"بحث",  
      members:"أعضاء الفريق",
      failed_to_get_data:"فشل الحصول على البيانات، الرجاء إعادة المحاولة",
      dashboard:"لوحة التحكم",
      profile:"حسابي الشخصي",
      mentors:"المرشدين",
      feedback:"الملاحظات",
      logout:"تسجيل خروج",
      Email:"البريد الإلكتروني",
      Passowrd:"كلمة المرور",
      Forget_Password:"نسيت كلمة المرور؟",
      enter_your_password:'كلمة المرور',
      enter_your_email:'البريد الإلكتروني',
      Login:"تسجيل دخول",
      Enter_a_vailed_email:"الرجاء التأكد من صحة البريد الإلكتروني",
      Logged_in_successfully:"تم تسجيل الدخول بنجاح",
      This_account_is_not_qualified:"هذا الحساب لم يتأهل",
      These_credentials_do_not_match_our_records:"البريد الإلكتروني وكلمة المرور غير متطابقين",
      email_is_required:"الرجاء إدخال البريد الإلكتروني",
      password_is_required:"الرجاء إدخال كلمة المرور",
      Rest_link_send_please_check_your_email:"تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني",
      Join_a_team:"الإنضمام إلى فريق",
      Create_a_team:"إنشاء فريق",
      Back:"رجوع",
      create_team:"إنشاء فريق",
      create:"إنشاء",
      Check_Your_Tags:"إختر المجالات التي تهمك",
      turn_on_other_people_will_see_your_team_and_they_can_join_to_it:"قم بتفعيلها إذا أردت أن يتمكن الأخرون من الإنضمام إلى فريقك",
      The_name_has_already_been_taken:"هذا الاسم قد تم إختياره من قبل",
      Team_created_successfully:"تم إنشاء الفريق بنجاح",
      Team_name_required:"الرجاء إدخال اسم الفريق",
      Team_english_description_required:"الرجاء إدخال وصف الفريق باللغة الأجنبية",
      Team_arabic_description_required:"الرجاء إدخال وصف الفريق باللغة العربية",
      Please_select_at_least_on_tag:"الرجاء إختيار مجال واحد على الأقل",
      Team_Name:"اسم الفريق",
      Team_Description_EN:"وصف الفريق باللغة الأجنبية",
      Team_Description:"وصف الفريق",
      Team_Description_Ar:"وصف الفريق باللغة العربية",
      Search_For_team:"ابحث عن فريق",
      Send_Request:"+ طلب إنضمام",
      Search:"بحث",
      no_result:"عذراً لم يتم العثور على أي نتائج",
      request:"الطلبات",
      memeber:"أعضاء الفريق",
      see_more:"المزيد",
      phone:"رقم الهاتف",
      email:"البريد الإلكتروني",
      university:"الجامعة",
      stream:"التخصص",
      graduation_year:"سنة التخرج",
      degree:"المستوى التعليمي",
      experience:"سنوات الخبرة",
      years:"سنة",
      skill:"المهارات",
      request_rejected:"مرفوض",
      request_accepted:"مقبول",
      reject:"رفض",
      accept:"قبول",
      request_list_updated_successfully:"تم تحديث قائمة الطلبات بنجاح",
      request_accepted_successfully:"تم قبول الطلب بنجاح",
      failed_accept_request:"فشل قبول الطلب الرجاء إعادة المحاولة",
      request_rejected_successfully:"تم رفض الطلب بنجاح",
      failed_reject_request:"فشل رفض الطلب الرجاء إعادة المحاولة",
      failed_to_get_request:"فشل الحصول على الطلبات الرجاء إعادة المحاولة",
      cancel_Request:"إلغاء الطلب",
      request_canceled_successfully:"تم إلفاء الطلب بنجاح",
      New_Password:"كلمة المرور الجديدة",
      enter_your_new_passowrd:"كلمة المرور الجديدة",
      Confirm_Password:"تأكيد كلمة المرور",
      Chagne_Password:"تغير كلمة المرور",
      password_should_be_at_least_8_char:"يجب أن تتكون كلمة المرور من ثمانية أحرف على الأقل",
      Confirm_password_is_required:"الرجاء تأكيد كلمة المرور",
      password_dosenot_match:"كلمة المرور غير متطابقة",
      welcome_to_expro:"أهلا بكم في EXPRO",
      Personal_Inforamtion:"المعلومات الشخصية",
      Skilles:"المهارات",
      Education:"التفاصيل المهنية والتعليمية",
      First_Name:"الاسم الأول",
      Last_Name:"الاسم الثاني",
      Phone_Number:"رقم الهاتف",
      Nationality:"الجنسية",
      ID:"رقم الهوية",
      zip_code:"رمز البريد",
      Address:"العنوان",
      next:"التالي",
      First_name_is_required:"الرجاء إدخال الاسم الأول",
      Last_name_is_required:"الرجاء إدخال الاسم الثاني",
      Phone_is_required:"الرجاء إدخال رقم الهاتف",
      Zip_cod_is_required:"الرجاء إدخال الرمز البريدي",
      Address_is_required:"الرجاء إدخال العنوان",
      Nationality_is_required:"الرجاء إدخال الجنسية",
      ID_number_is_required:"الرجاء إدخال رقم الهوية",
      invalid_id: "الرجاء إدخال رقم الهوية الصحيح",
      invalid_phone: "الرجاء إدخال رقم هاتف صحيح",
      check_Your_Skills:"اختر مهاراتك",
      Please_selsect_at_least_one_skill:"الرجاء إختيار مهارة واحدة على الأقل",
      Upload_resume:"تحميل السيرة الذاتية",
      or_just_drag_and_drop:"أو ضعها هنا",
      University:"الجامعة",
      University_Degree:"الشهادة الجامعية",
      Stream:"التخصص",
      Graduation_year:"سنة التخرج",
      Year_Of_Experince:"عدد سنوات الخبرة",
      submit:"إرسال",
      CV_is_required:"الرجاء تحميل السيرة الذاتية",
      University_name_is_required:"الرجاء إدخال اسم الجامعة",
      Degree_is_required:"الرجاء إدخال الشهادة الجامعية",
      Stream_is_required:"الرجاء إدخال التخصص",
      Graduation_year_is_required:"الرجاء إدخال سنة التخرج",
      Year_of_experince_is_required:"الرجاء إدخال عدد سنوات الخبرة",
      Registration_Success:"تم التسجيل بنجاح",
      The_email_has_already_been_taken:"هذا البريد الإلكتروني مسجل مسبقا",
      File_is_too_large:"حجم الملف أكبر من 6MB",
      Supported_files_fromat_are:"صغية الملفات المدعومة: ",
      sector:"القطاع",
      sector_is_required:"الرجاء إختيار القطاع",
      rounds:"الجولات",
      apply:"قدم الآن",
      this_field_is_required:"هذا الحقل إجباري",
      text_length_should_be_between:"يجب أن يكون النص بين",
      number_should_be_between:"يجب أن يكون الرقم بين",
      date_should_be_between:"يجب ان يكون التاريخ بين",
      email_not_found:"البريد الإلكتروني غير موجود",
      send_reset_link:'إرسال رابط إعادة التعيين',
      field:"المجال",
      Book_Now:"احجز الآن",
      please_Select_Session:"الرجاء إختيار وقت لحجز جلسة",
      session_booked_successfully:"تم حجظ الجلسة بنجاح",
      failed_to_book_session:"فشل حجز الجلسة",
      Book_a_session:"احجز جلسة",
      no_available_sessions:"عذراً, لا يوجد جلسات متوفرة",
      Search_For_Mentor:"إبحث عن مرشد",
      my_session:"جلساتي",
      failed_to_get_sessions:"فشل الحصول على الجلسات",
      session_list_updated_successfully:"تم تحديث قائمة الجلسات بنجاح",
      mentor_list_updated_successfully:"تم تحديث قائمة المرشدين بنجاح",
      no_booked_sessions:"عذراً, لا يوجد جلسات محجوزة",
      mentor_name:"اسم المرشد",
      date:"التاريخ",
      time:"الوقت",
      download_file:"تحميل الملف",
      no_uploaded_file:"لم يتم تحميل ملف",
      download_image:"تحميل الصورة",
      no_uploaded_image:"لم يتم تحميل صورة",
      no_feedback:"عذراً، لا يوجد ملاحظات",
      register_now:"سجل الآن",
      about_expro:"نبذة عن EXPRO",
      explore_kafa:"اكتشف كفاءةثون",
      faqs:"الأسئلة الشائعة",
      
      event_start:"إنطلاق فعاليات كفاءات 2024 في ....",
      about_q1:'What is the Efficiency and Government Projects Authority (EXPRO)?',
      about_a1:"EXPRO was formed pursuant to the Cabinet Resolution issued on February 23, 2021 corresponding to Rajab 11, 1442 AH, pursuant to Resolution No. (389) , which stipulates the inclusion of the National Program for Supporting Project Management, Operation and Maintenance in Public Entities (Projects) in the Center for Achieving Spending Efficiency, and transforming the center into the Authority for Spending Efficiency and Government Projects.",
      about_q2:"The Purpose of Establishing the Authority:",
      about_a2_1:"Contributing to achieving spending efficiency in government agencies",
      about_a2_2:"Improving the quality of projects, assets and facilities, infrastructure planning, programmes, initiatives and operational processes funded by the state’s general budget",
      about_a2_3:"Follow up on the implementation of these entities’ programmes and initiatives in a manner that achieves the authority’s objectives.",
      explore_t1:"AI Financial Forecasting and Analysis Solution",
      challenge:"challenge",
      explore_t2:"Develop a software solution that enables relevant entities to accurately calculate, forecast and allocate budgets to upcoming projects or RFPs. The solution should streamline budget management, integrate sustainability metrics for resource re-use, and incorporate a budget variance tool to drive continuous improvement. The tool must enhance decision-making, promote resource efficiency, and support sustainable project planning.",
      Create_session:"إضافة جلسة",
      team_name:"اسم الفريق",
      session_time:"وقت الجلسة",
      status_action:"الحالة/الإجراء",
      no_session:"عذراً لا يوجد جلسات",
      status:"الحالة",
      are_you_sure_you_want_to_cancel_this_session:"هل أنت متأكد من إلغاء هذه الجلسة",
      are_you_sure_you_want_to_delete_this_session:"هل أنت متأكد من حذف هذه الجلسة",
      yes:"نعم",
      no:"كلا",
      session_no:"الجلسة رقم",
      session_canceled_successfully:"تم إلفاء الجلسة بنجاح",
      session_cancel_failed:"فشل إلغاء الجلسة",
      session_date:"تاريخ الجلسة",
      date_is_required:"الرجاء إدخال تاريخ الجلسة",
      start_time_is_required:"الرجاء إدخال وقت بدء الجلسة",
      end_time_is_required:"الرجاء إدخال وقت إنتهاء الجلسة",
      session_start_time:"وقت بدأ الجلسة",
      session_end:"Session end time",
      end_time_should_be_larger:"يجب أن يكون وقت إنتهاء الجلسة أكبر ب 10 دقائق على الأقل من وقت بدأ الجلسة",
      session_created_successfully:"تم إضافة الجلسسة بنجاح",
      failed_to_crearte_sesssion:"فشل إضافةالجلسة",
      delete:"حذف",
      session_deleted_successfully:"تم حذف الجلسة بنجاح",
      session_deleted_failed:"فشل حذف الجلسة",
      healthcare:"الرعاية الطبية",
      construction:"البناء",
      event_and_coferencese:"الفعاليات والمؤتمرات",
      Industries_In_Focus:"القطاعات المستهدفة",
      judges:"الحكام",      
      faq:"الأسئلة الشائعة",
      sponsers:"الرعاة",
      day:"يوم",
      hours:"ساعات",
      minutes:"دقائق",
      seconds:"ثواني",
      contact_us:"تواصل معنا",
      Continue_with_email:"تابع ببريدك الإلكتروني",
      cor:"بلد الإقامة",
      cor_is_required:"الرجاء إدخال بلد الإقامة",
      full_name_is_required:"الرجاء إدخال الاسم الكامل",
      full_Name:"الاسم الكامل",
      jobTitle:"المسمى الوظيفي الحالي",
      jobTitle_is_required:"الرجاء إدخال االمسمى الوظيفي",
      terms_label:"بإقرارك على الاستمرار، فأنت توافق على سياسة السرية لهاكر ايرث وتوافق على شروط خدمتنا ",
      click_to_Read:"انقر للذهاب لموقع الهيئة",
      see_less:"إخفاء",
      Eligibility_Criteria:"معاير الأهلية",
      accetp_terms:"يجب عليك قبول القواعد والشروط لتتمكن من التسجيل",
      accetp_terms_team:"يجب عليك قبول القواعد والشروط لتتمكن من الإنضمام أو إنشاء فريق",
      step:"الخطوة",
      partners:"الرعاة",
      what_next:"رحلة كفاءةثون",
      dob:"تاريخ الميلاد",
      cv:"السيرة الذاتية المحدثة",
      dob_is_required:"الرجاء إدخال تاريخ الميلاد",
      skills_exprtise:"المهارات",
      lang_prof:"اللغة",
      lang_prof_is_required:"الرجاء إختيار اللغة",
      educational_level:"المستوى التعليمي",
      educational_level_is_required:"الرجاء إدخال المستوى التعليمي",
      last_attended_university:"اخر جامعة التحقت بها",
      last_attended_university_is_required:"الرجاء اخر جامعة التحقت بها",
      'The Rules and Guidelines':"القواعد والإرشادات",
      'max date of birth is 01-01-2010':"يجب أن لا يتعدى تاريخ الميلاد 01-01-2010",
      'expertise':'خبرة',
      'description':"الوصف",
      'company':'الشركة',
      'Invite member to your team':"دعوة أحد الأعضاء للانضمام إلى فريقك",
      'Send invitation':"إرسل دعوة",
      'Invite member':'دعوة صديق',
      'session end time':"وقت إنتهاء الجلسة",
      'This applicant already has team':"هذا المتسابق لديه فريق",
      'invitation date':'تاريخ الدعوة',
      'action':'الإجراءت',
      'Action':'الإجراءت',
      'This account is not available':'هذا  الحساب غير متوفر ',
      'Applicant remove successfully':"تم إزالة المتسابق بنجاح",
      'Failed to remove applicant remove':"فشل إزالة المتسابق",
      'Cancel':"إلغاء",
      'Remove':"حذف",
      'Invitation send successfully':"تم إرسال الدعوة بنجاح",
      'Idea addition':"إضافة الفكرة",
      'Title':"العنوان",
      'Write about your idea':"اكتب عن فكرتك",
      'Description (English)':"الوصف (بالإنجيزي)",
      'Description (Arabic)':"الوصف (بالعربي)",
      'Idea addition':"إضافة الفكرة",
      'Update idea':"تعديل الفكرة",
      'What should an ideal final solution include?':"ما الذي يجب أن يشتمل عليه الحل النهائي المثالي؟",
      'Industries':"الصناعة",
      'Title is required':"الرجاء إدخال عنوان",
      'Industry is required':"الرجاء اختيار الصناعة",
      'Arabic description is required':"الرجاء إدخال الوصف بالعربي",
      'Description is required':"الرجاء إدخال الوصف بالإنجليزي",
      "Idea added successfully":"تم إضافة الفكرة بنجاح",
      "Idea updated successfully":"تم تعديل الفكرة بنجاح",
      "Failed to add idea":"فشل إضافة الفكرة",
      "Failed to update idea":"فشل تعديل الفكرة",
      "Attachments": "المرفقات",
      "unselect all":"إلغاء الكل",
      "max size":"الحجم الأقصى",
      "Welcome":"مرحباً",
      'Password should have':"يجب أن تتضمن كلمة المرور",
      'Minimum 8 characters':"8 أحرف على الأقل",
      'At least 1 number':"رقم واحد على الأقل",
      'At least 1 special character':"رمز واحد على الأقل",
      'At least 1 small character':"حرف صغير على الأقل",
      'At least 1 capital character':"حرف كبير على الأقل",
      'Some fileds has error':"الرجاء التأكد من جميع الخانات",
      'Email already invitted':"لقد دعوت هذا الشخص من قبل",
      'Employment Status':"حالة التوظيف",
      'identification number':"رقم الهوية",
      'Identification number is required':"الرجاء إدخال رقم الهوية",
      'Create or Join Team':"إنشاء فريق أو الإنضمام إلى فريق",
      'There is no invitation':"لا يوجد دعوات",
      'Allow other to view your team and join if they wish':"اسمح للآخرين بالاطلاع على فريقك والانضمام إليه إذا رغبوا",
      'Submit your idea':"قدم فكرتك",
      'Idea submission':"قدم الفكرة",
      'Invitation List':"قائمة المدعوين",
      'Search by name or email':"ابحث بالاسم او بالبريد الإلركتروني",
      'Enter a name or email':"الرجاء إدخال الاسم أو البريد الإلكتروني",
      'Sorry no result found':"عذراً، لم يتم العثور على نتائج",
      'Invitation canceled successfully':"تم إلفاء الدعوة بنجاح",
      'Accept or reject invitation':"قبول أو رفض الدعوة",
      'Reject Invitation':"رفض الدعوة",
      'Accept Invitation':"قبول الدعوة",
      'Invitation accepted successfully, please login':"تم قبول الدعوة بنجاح، الرجاء تسجيل الدخول",
      'Invitation rejected successfully':"تم رفض الدعوة بنجاح",
      'Failed to reject invitation':"فشل رفض الدعوة",
      'Failed to accept invitation':"فشل قبول الدعوة",
      'Idea Title':"عنوان الفكرة",
      'What is expected of you?':"ما هو المتوقع منك؟",
      'Research and Analysis':"البحث والتحليل",
      'Conduct industry-specific research for Construction, Events and Healthcare sectors to understand unique budget challenges.':"إجراء أبحاث خاصة بالقطاعات في مجالات البناء، والفعاليات، والرعاية الصحية لفهم التحديات الفريدة في الميزانية.",
      'Analyze existing budget allocation tools and sustainability metrics to identify gaps and opportunities.':"تحليل أدوات تخصيص الميزانية الحالية وقياسات الاستدامة لتحديد الفجوات والفرص.",
      'Upload File':"حمل الملف",
      'Preliminary Design and Architecture':"التصميم الأولي",
      'Draft the software architecture, including how the characteristics of the ideal solution will be integrated.':" وضع مسودة لهندسة البرمجيات، بما في ذلك كيفية دمج كل من الأعمدة العشرة. انقر هنا لقراءة الأعمدة.",
      'Outline data requirements, particularly for the Comprehensive Cost Database and integration points with existing systems.':" تحديد متطلبات البيانات، خصوصًا لقاعدة بيانات التكاليف الشاملة ونقاط التكامل مع الأنظمة الحالية.",

      'Technical Stack Selection':"اختيار التقنية:",
      'Choose the appropriate programming languages, frameworks, databases, and AI models.':" اختيار لغات البرمجة المناسبة، والإطارات، وقواعد البيانات، ونماذج الذكاء الاصطناعي.",
      'Decide on cloud platforms or on-premise solutions for deployment.':" اتخاذ قرار بشأن منصات السحابة أو الحلول المحلية للتنفيذ.",

      'Initial Prototyping':"النمذجة الأولية:",
      'Begin developing a basic prototype.':" البدء في تطوير نموذج أولي أساسي، مع التركيز على عمود أو عمودين رئيسيين (مثل أدوات التنبؤ المعتمدة على الذكاء الاصطناعي التوليدي وتخصيص الميزانية).",

      'Collaboration Setup':"إعداد التعاون:",
      'Form teams and set up collaboration tools (e.g., GitHub, Slack, Jira) to ensure efficient communication and workflow.':" تشكيل فرق وإعداد أدوات التعاون (مثل GitHub وSlack وJira) لضمان التواصل الفعال وسير العمل.",
      'Note: max size is 20 MB and allowed extensions: pdf - png - jpg - jpeg - pptx - xlsx':"ملاحظة: الحجم الاقصى هو 20MB والملفات المسموحة هي: pdf - png -jpg -jpeg - pptx - xlsx",
      'Allowed file extestions: pdf - png - jpg - jpeg - pptx -xlsx':"الملفات المسموحة: pdf - png - jpg - jpeg - pptx -xlsx",
      'Max size: 20MB':"الحجم الأقصى : 20MB",
      'Download File':"تنزيل الملف",
      'Description':"الوصف",
      'Skills':"المهارات",
      'This invitation has expired':"لقد إنتهت صلاحية هذه الدعوة",
      'Team List':"قائمة الفريق",
      'Assign Position':"تعين منصب",
      'Position':"المنصب",
      'Position is required':"الرجاء إدخال المنصب",
      'Position assign successfully':"تم تعيين المنصب بنجاح",
      'Failed to assign postion':"فشل تعيين المنصب",
      'Edit Position':"تعديل المنصب",
      'password changed succssfully':"تعم تغيير كلمة المرور بنجاح",
      'Share my idea details':"أشارك تفاصيل فكرتي",
      'Unbook':"إلغاء الحجز",
      'available':"متاح",
      'attended':"تم حضورها",
      'Preparation Phase':"مرحلة التهيئة",
      'Day 1 Deliverables: Foundation and Core Features':"مخرجات اليوم الأول: الأساسيات والميزات الأساسية",
      'Day 2 Deliverables: Advanced Features and Final Integration':"مخرجات اليوم الثاني: الميزات المتقدمة والتكامل النهائي",
      'Functional Prototype – Core Features':"النموذج الأولي الوظيفي – الميزات الأساسية:",
      'Generative AI-Driven Forecasting:':"توقعات الذكاء الاصطناعي التوليدي:",
      'Develop and demonstrate a basic AI model that forecasts budget requirements based on provided project data.':"تطوير وعرض نموذج ذكاء اصطناعي بسيط يقوم بتوقع متطلبات الميزانية بناءً على بيانات المشروع المقدمة.",
      'Comprehensive Cost Database:':"قاعدة بيانات التكلفة الشاملة:",
      'Integrate a foundational cost database, which participants can populate with sample data relevant to Construction and Healthcare.':"دمج قاعدة بيانات تكلفة أساسية يمكن للمشاركين ملؤها ببيانات عينة ذات صلة بقطاعي البناء والرعاية الصحية",
      'Initial Budget Allocation Tools:':"أدوات تخصيص الميزانية الأولية:",
      'Implement a basic version of the budget allocation tools, allowing users to allocate resources based on forecasted needs.':"تنفيذ إصدار بسيط من أدوات تخصيص الميزانية، مما يسمح للمستخدمين بتخصيص الموارد بناءً على الاحتياجات المتوقعة.",
      'Technical Documentation (Part 1)':"التوثيق الفني (الجزء 1)",
      'Architecture Overview:':"نظرة عامة على الهندسة المعمارية:",
      'Document the overall software architecture, focusing on the integration of the Generative AI model and the Cost Database.':"توثيق بنية البرمجيات الشاملة مع التركيز على دمج نموذج الذكاء الاصطناعي التوليدي وقاعدة بيانات التكلفة.",
      'Data Flow Diagrams:':"مخططات تدفق البيانات:",
      'Provide diagrams that show how data flows between the core components developed on Day 1.':"تقديم مخططات تظهر كيفية تدفق البيانات بين المكونات الرئيسية التي تم تطويرها في اليوم الأول.",
      'Team Presentation – Initial Concept':"عرض الفريق – المفهوم الأولي",
      'Problem Statement & Solution Approach:':"عرض الإشكالية واستراتيجية الحل :",
      'Present the problem the team is addressing, their approach to solving it, and how the core features align with the hackathon objectives.':"تقديم المشكلة التي يتعامل معها الفريق، وطريقته في حلها، وكيف تتوافق الميزات الأساسية مع أهداف الهاكاثون.",
      'Demo of Core Features:':"عرض توضيحي للميزات الأساسية:",
      'Perform a live demo of the functional prototype, showcasing the AI-driven forecasting and initial budget allocation.':"إجراء عرض مباشر للنموذج الأولي الوظيفي، مع عرض التوقعات المدفوعة بالذكاء الاصطناعي وأدوات تخصيص الميزانية الأولية.",
      'Enhanced Functional Prototype – Advanced Features':"االنموذج الوظيفي المطور - الميزات المتقدمة",
      'Sustainability Metrics & Resource Reusability:':"مقاييس الاستدامة وإعادة استخدام الموارد:",
      'Add features that track and optimize resource usage, integrating sustainability metrics.':"إضافة ميزات تتبع وتحسين استخدام الموارد، مع دمج مقاييس الاستدامة.",
      'Scalable and User-Friendly Interface:':"واجهة مستخدم قابلة للتوسع وسهلة الاستخدام:",
      'Refine the user interface to make it more intuitive and scalable.':"تحسين واجهة المستخدم لجعلها أكثر سهولة وقابلة للتوسع.",
      'Real-Time Reporting and Analytics:':"التقارير والتحليلات في الوقت الفعلي:",
      'Implement real-time reporting tools that allow users to track budget variance and project status.':"تنفيذ أدوات تقارير في الوقت الفعلي تسمح للمستخدمين بتتبع تباين الميزانية وحالة المشروع.",
      'Technical Documentation (Part 2)':"التوثيق الفني (الجزء الثاني)",
      'Detailed Component Interactions:':"تفاعلات المكونات التفصيلية:",
      'Document the interactions between advanced features added on Day 2, including how sustainability metrics are calculated and reported.':"توثيق التفاعلات بين الميزات المتقدمة المضافة في اليوم الثاني، بما في ذلك كيفية حساب مقاييس الاستدامة والإبلاغ عنها.",
      'Integration Plan:':"خطة التكامل:",
      'Provide a plan for how the prototype could be integrated with existing systems in the target industries.':"تقديم خطة حول كيفية دمج النموذج الأولي مع الأنظمة القائمة في الصناعات المستهدفة.",
      'Final Presentation – Complete Solution':"العرض النهائي – الحل المتكامل",
      'Full Solution Overview:':"نظرة عامة على الحل الكامل:",
      'Present the complete solution, including all features developed across both days.':"تقديم الحل الكامل، بما في ذلك جميع الميزات التي تم تطويرها على مدار اليومين.",
      'Future Development Roadmap:':"خارطة طريق التنمية المستقبلية:",
      'Outline a roadmap for future enhancements and scalability.':"توضيح خريطة طريق للتطوير المستقبلي والقابلية للتوسع.",
      'Final Live Demo:':"العرض النهائي المباشر:",
      'Conduct a final live demo that showcases the full range of features, emphasizing the software’s impact on decision-making and resource efficiency.':"إجراء عرض توضيحي نهائي مباشر يبرز جميع الميزات ويظهر تأثير البرنامج على اتخاذ القرارات وكفاءة استخدام الموارد.",
      'Merge with a team':"دمج مع فريق",
      'Search for team':"إبحث عن فريق",
      'Received request':"الطلبات الواردة",
      'Sent request':"الطلبات الصادرة",
      'Idea':"الفكرة",
      'Team':"الفريق",
      'Request date':"تاريخ الدعوة",
      'Request updated successfully':"تم تحديث الدعوات بنجاح",
      'Request canceled successfully':"تم إلغاء طلب الدعوة بنجاح",
      'Failed to cancel request':"فشل إلغاء الطلب",
      'Merging request sent successfully':"تم إرسال طلب دمج الفريق بنجاح",
      'Session Timeout, please login again':"إنتهت الجلسة، الرجاء إعادة تسجيل الدخول",
      captcha_is_required:"هذ الحقل إجباري",
      captcha_dose_not_match:"إن النص المدخل لا يتطابق مع النص أعلاه",
      'No received request':"لا يوجد طلبات واردة",
      'No sent request':"لا يوجد طلبات صادرة",
      'Attachments upload successfully':"تم رفع الملف بنجاح",
      'All':"الكل",
      'Booked':"جلسات محجوزة",
      'Email not found':"البريد الإلكترني غير موجود",
      'View Team':"عرض الفريق",
      'Has Finished':"إنتهت",
      'Invalid link':"هذا الرابط غير صحيح",
      'View File':"عرض الملف",
      'Send Score':"إرسال النتيجة",
      'Score':"النتيجة",
      'Percentage':"النسبة",
      'Set Score between 1 and 100':"ضع نتيجة بين 1 و 100",
      'Score set successfully':"تم إرسال النتيجة بنجاح",
      'Failed to set score':"فشل إرسال النتيجة",
      'Feedback is required':"يجب إدخال الملاحظات",
      'Feedback sent successfully':"تم إرسال الملاحظات بنجاح",
      'Failed to send feedback':"فشل إرسال الملاحظات",
      'Feedback':"ملاحظات",
      'Send Feedback':"إرسال الملاحظات",
      'Sorry, there is no team':"عذراً، لا يوجد فرق",
      'Feedback & total Score':"الملاحظات والنتيجة النهائية",
      'Total Score':"النتيجة النهائية",
      'all':"الكل",
      'pending':"قيد الإنتظار",
      'complete':"المُنجز",
      'There is conflicts with other sessions':"هنالك تعارض مع وقت إستشارة أخرى",
      'Log_in_successfully':"تم تسجيل الدخول بنجاح",
      'Please enter a valid link':"الرجاء التأكد من صحة الرابط",
      "Kafa'athon Success and Knowledge Partners":"شركاء النجاح والمعرفة في كفاءةثون",
      'Assets and facilities management':"⁠إدارة الأصول والمرافق",
      'Resources allocation specific efficiency enhancement':"⁠تحسين كفاءة تخصيص الموارد",
      'Efficient procurement and process optimization':"⁠الكفاءة في المشتريات وتحسين العمليات",
      'project management':"⁠إدارة المشاريع",
      track_2_title:" تعزيز الكفاءة والاستدامة \n  تحسين الأصول والموارد وعمليات المشتريات لتحقيق تأثير مستدام",
      track_1_title:"الكفاءة التشغيلية \n  حلّ التخطيط المالي باستخدام الذكاء الاصطناعي",
      'Track One':"المسار الأول",
      'Track Two':"المسار الثاني",
      "Kafa'athon intro":"مقدمة كفاءةثون",
      'Explore Track Two':"اكتشف المسار الثاني",
      'Explore Track One':"اكتشف المسار الأول",
      'Track Two Journey':"رحلة المسار الثاني",
      'Track One Journey':"رحلة المسار الأول",
      '':"",
      '':"",
       

      




      
    
    
    
    },
  },
})
export default i18n
