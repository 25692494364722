
<template>
      <div class="pf ov ra oh bgNavy m_h" style="width:500px">
      <div class="ov10 ml10 ra pointer z200" style="direction: ltr;" @click="changeLang()"><span class="mdi mdi-earth fs24 cw vm mt2"></span></div>
      <img src="@/assets/imgs/patternBg/faqBg.png" alt="" class="ov w100" style="height: 100%;"/>
      <div class="cc w100">
        <router-link :to="{ name: 'home' }"><img src="@/assets/imgs/logo_2_w.png" class="w100" style="max-width: 200px;" alt=""></router-link>
      </div>
    </div>
    <div class="h m_sb pv15 bgNavy c">
      <div class="ov10 ml10 ra pointer z200" style="direction: ltr;" @click="changeLang()"><span class="mdi mdi-earth fs24 cw vm mt2"></span> <span class="fs16 b cw upper vm">({{ $i18n.locale }})</span></div>
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/imgs/logo_2_w.png" alt="" class="w100 g_nm" style="max-width: 200px;"/>
      </router-link>
    </div>
    <div v-if="$store.state.loader==1" class="z2000 pf ov bgw60">
      <div class="cc">
        <img src="@/assets/imgs/loader.gif"  style="max-width: 250px; opacity: 0.7;"/>
      </div>
    </div>
    <div class="pf ov bgb50 z2000" v-if="$store.state.showSuccessModal">
    <div class="cc cw w80 customModal round4x sh30 ph30 pv25 m_pv10 ">
      <div class="cc w100">
          <div class="g_nm round100 p40 m_p30" style="border: 3px solid #fff;"><span class="cc fs50 m_fs40 mdi mdi-check"></span></div>
      <div class="fs30 m_fs24 b mt20">{{ $store.state.successMsg }}</div>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'RegLayout',
  created(){
    if(this.$cookies.isKey("_HackLang")){
      if(this.$cookies.get("_HackLang")=='en'){
        this.$i18n.locale='ar';
      }
      else{
        this.$i18n.locale='en';
      }
      this.changeLang()
    }
    
  },
  
  methods: {
    logoutApplicant(){
      this.$cookies.remove("_HackAppAT")
      this.$cookies.remove("_HackAppU")
      this.$router.push({ name: 'ApplicantLogin'});  
    },
    changeLang(){
      if(this.$i18n.locale=="en"){
        $('html').attr('lang', 'ar');  
        this.$i18n.locale='ar';
        $('body').addClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'ar', expirationTime);
      }
      else{
        $('html').attr('lang', 'en');  
        this.$i18n.locale='en';
        $('body').removeClass('rtl');
        const expirationTime =  100 * 60 * 60;
        this.$cookies.set("_HackLang", 'en', expirationTime);
      }
    },
  }
}
</script>
